<script>
import UiLogoIcon from 'at-ui/components/icons/LogoIcon.vue'

export default {
  components: {
    UiLogoIcon,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    user() {
      return this.$auth.getCurrentUser()
    },
    username() {
      return this.user?.profile.name
    },
    firstLetter() {
      return this.username?.[0]
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen
    },
    async logout() {
      await this.$auth.signout()
    },
  },
}
</script>

<template>
  <header class="flex">
    <nuxt-link
      :to="{ name: 'index' }"
      title="Navigate home"
      class="absolute overflow-hidden rounded-b border-b border-gray-50 shadow"
    >
      <ui-logo-icon class="-my-[1.3rem] h-32 w-44" />
    </nuxt-link>

    <div v-if="user" class="relative ml-auto self-center">
      <button
        type="button"
        class="flex h-8 w-8 max-w-xs items-center justify-center rounded-full bg-orange-500 pt-0.5 text-lg font-bold text-blue-700 ring-offset-2 ring-offset-blue-900 focus:outline-none focus:ring-2 focus:ring-orange-500"
        :class="{ 'ring-2 ring-orange-500': isOpen }"
        @click="toggleIsOpen"
      >
        {{ firstLetter }}
      </button>

      <div
        v-if="isOpen"
        class="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 text-center text-sm text-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <span class="block border-b border-gray-200 px-4 py-2">
          {{ username }}
        </span>

        <button type="button" class="px-4 py-2 text-center" @click="logout">
          Sign out
        </button>
      </div>
    </div>
  </header>
</template>
