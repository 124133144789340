<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div
    class="flex w-full max-w-sm items-center gap-2 rounded-lg border border-gray-300 bg-white p-4 shadow"
    :class="[
      { 'text-error-500': status === 'ERROR' },
      { 'text-success-500': status === 'SUCCESS' },
    ]"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6 flex-shrink-0"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        v-if="status === 'ERROR'"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
      <path
        v-else-if="status === 'SUCCESS'"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>

    {{ message }}
  </div>
</template>
