<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 117">
    <defs>
      <linearGradient id="a" x1="0%" y1="50%" y2="50%">
        <stop offset="0%" stop-color="#EEE680" />
        <stop offset="100%" stop-color="#DCCD00" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <path fill="#FFF" d="M0 0h220v117H0z" />
      <path fill="#DCCD00" d="M198 16h11v55h-11zM198 76h11v26h-11z" />
      <path fill="#DCCD00" d="M12 76h187v26H12z" />
      <path fill="url(#a)" d="M0 1h187v55H0z" transform="translate(12 15)" />
      <path
        fill="#004428"
        fill-rule="nonzero"
        d="M53.818 75.556c.461-.886-.814-2.282-.888-3.234-.179-2.316.22-4.76 1.164-6.577.702-1.37 2.075-2.441 3.193-3.57 2.506-2.55 4.828-4.98 7.53-7.17 1.218-.992 2.606-1.5 3.836-2.57.93-.817 1.718-1.883 2.524-2.804.8-.69 1.595-1.389 2.387-2.08 1.944-1.859 4.842-4.415 7.345-5.065.16-.022.312-.048.471-.074l2.267 1.477c.806.42 1.606.838 2.413 1.253.945.664 1.891 1.322 2.837 1.978 2.273 1.1 5.255.92 7.245 2.592.041.034.073.06.106.093.563.536 1.203 1.363 1.322 2.15.179 1.227-.429 2.58.913 3.212.958.444 1.804.04 2.837.135.603.048 1.182.383 1.837.228.674-.275 1.341-.557 2.009-.83.854-.089 1.582.422 2.448.22 2.242-.544 1.994-4.054 2.096-6 .058-1.247.54-2.114.119-3.37-.543-1.602-1.836-2.407-3.407-2.723-1.104-.228-1.619-.543-2.543.295v.216h-.213c0-.423-.733-.725-1.056-.86 0 .383-.597.61-.848.86-2.288 0-2.492-5.229-2.255-7.082.45-1.796 1.775-5.385 2.224-7.186.35-1.414.455-2.932 1.058-4.207.675-1.463 3.912-6.026 4.48-7.551.105-.327.156-.623.133-1.021-.062-1.181-1.12-1.266-2.146-1.291h-.434c-.883 1.338-3.116 4.11-3.894 5.518-.504 1.463-2.77 6.499-3.271 7.96-.497.76-.991 1.513-1.488 2.262-.53 1.268-1.065 2.53-1.6 3.792-1.673 0-4.184-2.24-5.585-3.12-1.966-1.242-3.981-1.429-6.203-2.194-2.09-.727-3.226-1.867-5.937-1.19-2.996.74-4.509 3.223-6.301 5.564-.516.61-1.037 1.216-1.56 1.819-.463.692-1.06 1.477-1.132 2.355l.08 1.665c-.183.96-2.354 4.536-3.066 5.344-1.072 1.112-2.15 2.225-3.222 3.34-1.42 1.026-3.14 1.242-4.468 2.597-2.796 2.831-4.337 6.663-7.22 9.422-.812.766-3.641 1.872-3.826 2.395-.505 1.417.613 3.166.918 4.49.513 2.288.005 4.294 1.435 6.147.661.853 1.421.511 2.253.974.627.346 1.324 1.71 2.145 1.86h.292c.17-.033.345-.122.526-.29.04-.048.08-.1.12-.154"
      />
      <path
        fill="#FFF"
        fill-rule="nonzero"
        d="M17 87.601L21.269 89 17 90.399V93l8-2.955v-2.089L17 85v2.601z"
      />
      <path
        fill="#40735E"
        fill-rule="nonzero"
        d="M38.308 92.801l-3.298-8.665h-1.687L30 92.801h1.86l.683-1.93h3.174l.682 1.93h1.91zm-3.186-3.577h-1.985l.558-1.572c.174-.508.348-1.028.435-1.349.086.321.26.841.433 1.349l.559 1.572zm12.922-3.305C47.498 84.793 46.506 84 44.87 84c-2.268 0-3.857 1.833-3.857 4.47 0 2.735 1.526 4.467 3.796 4.467 1.574 0 2.616-.73 3.211-1.955l-1.463-.867c-.46.768-.918 1.102-1.7 1.102-1.3 0-2.058-1.188-2.058-2.748 0-1.61.745-2.749 2.046-2.749.781 0 1.278.348 1.588.942l1.612-.743zm11.072 6.882l-3.298-8.665h-1.686l-3.325 8.665h1.86l.683-1.93h3.174l.683 1.93h1.909zm-3.187-3.577h-1.983l.558-1.572c.172-.508.347-1.028.433-1.349.086.321.261.841.435 1.349l.557 1.572zm13.69-.755c0-3.169-1.835-4.333-4.314-4.333h-2.717v8.665h2.593c2.23 0 4.439-1.286 4.439-4.332zm-1.785 0c0 1.584-.83 2.65-2.653 2.65h-.857V85.82h.918c1.836 0 2.592.88 2.592 2.65zm11.594 4.332v-1.695h-4.476v-2.092h2.492V87.33h-2.492v-1.497h4.29v-1.697h-6.026v8.665h6.212zm11.83 0v-8.665h-1.71l-1.75 3.924a20.308 20.308 0 0 0-.582 1.45c-.113-.311-.372-.966-.596-1.45l-1.81-3.924h-1.674v8.665h1.699v-3.18c0-.47-.025-1.573-.025-1.907.124.322.384.941.608 1.436l1.76 3.85 1.762-3.85c.223-.495.483-1.114.607-1.436 0 .334-.025 1.436-.025 1.906v3.181h1.736zm5.952-8.665h-1.736v8.667h1.736v-8.667zM108 85.92c-.547-1.126-1.54-1.919-3.176-1.919-2.269 0-3.856 1.833-3.856 4.47 0 2.735 1.524 4.467 3.794 4.467 1.575 0 2.615-.73 3.211-1.955l-1.462-.867c-.458.768-.918 1.102-1.698 1.102-1.303 0-2.06-1.188-2.06-2.748 0-1.61.744-2.749 2.047-2.749.78 0 1.276.348 1.587.942l1.613-.743z"
      />
      <path
        fill="#004428"
        fill-rule="nonzero"
        d="M116.663 85.857v-1.72H110v1.72h2.47v7.006h1.721v-7.006h2.472zm10.474 7.006l-1.65-3.39c1.097-.436 1.65-1.26 1.65-2.568 0-1.858-1.342-2.768-2.963-2.768h-4.007v8.726h1.72v-3.141h1.733l1.539 3.141h1.978zm-1.77-5.92c0 .76-.43 1.134-1.23 1.134h-2.25v-2.244h2.25c.762 0 1.23.324 1.23 1.11zm12.86 5.92l-3.27-8.726h-1.671l-3.297 8.726h1.847l.674-1.944h3.148l.677 1.944h1.892zm-3.16-3.603h-1.968l.555-1.583c.172-.51.344-1.034.43-1.358.088.324.258.847.43 1.358l.552 1.583zm13.572 3.603v-8.726h-1.696v3.69c0 .487.025 1.584.025 1.907-.11-.224-.394-.66-.602-.985l-3.05-4.612h-1.645v8.726h1.694v-3.839c0-.486-.025-1.583-.025-1.908.111.224.394.661.603.985l3.16 4.762h1.536zm10.365-2.643c0-1.894-1.513-2.368-2.878-2.692-1.363-.324-1.732-.499-1.732-1.047 0-.386.308-.785 1.228-.785.787 0 1.402.324 1.954.898l1.242-1.223c-.81-.86-1.794-1.371-3.134-1.371-1.696 0-3.073.973-3.073 2.556 0 1.72 1.106 2.231 2.691 2.605 1.623.374 1.918.624 1.918 1.184 0 .661-.48.948-1.5.948-.823 0-1.598-.287-2.2-.997l-1.241 1.121c.65.973 1.906 1.583 3.343 1.583 2.35 0 3.382-1.121 3.382-2.78zm9.823-4.374v-1.709h-6.16v8.726h1.72v-3.677h2.593V87.49h-2.592v-1.644h4.439zm9.527 7.017v-1.707h-4.44v-2.107h2.473v-1.696h-2.472v-1.507h4.254v-1.709h-5.975v8.726h6.16zm10.646 0l-1.648-3.39c1.094-.436 1.648-1.26 1.648-2.568 0-1.858-1.34-2.768-2.963-2.768h-4.009v8.726h1.721v-3.141h1.734l1.537 3.141H189zm-1.771-5.92c0 .76-.43 1.134-1.23 1.134h-2.25v-2.244H186c.763 0 1.23.324 1.23 1.11z"
      />
    </g>
  </svg>
</template>
